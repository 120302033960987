<template>
    <va-modal
        v-model="s_data"
        @cancel="onCancel"
        hideDefaultActions
        class="flex xs12"
        title="Tickets Modal"
        size="large"
        ok-text="Ok">
        <va-data-table
            :fields="ticketsFields"
            :data="ticketsFilteredData"
            :per-page="5"
        />
        <div class="pt-2">
        <va-button
            slot="actions"
            @click="onCancel"
            color="primary"
            class="mr-2"
        > Close </va-button>
        </div>
    </va-modal>
</template>
<script>
export default {
   name: 'TicketData',
   props: {
       data: {
           required: true,
       },
       ticketsFilteredData: {
           required: true,
       },
   },
   data() {
       return {
           s_data: false,
       }
   },
   computed: {
        ticketsFields(){
            return [{
                name: 'header',
                title: 'Header',
                sortField: 'header',
                width: '40%',
            },
            {
                name: 'category',
                title: 'Category',
                sortField: 'category',
                width: '10%',
            }, 
            {
                name: 'answerLabel',
                title: 'Label',
                sortField: 'answerLabel',
                width: '10%',
            }, 
            {
                name: 'datails',
                title: 'Details',
                sortField: 'datails',
                width: '10%',
            },
            {
                name: 'answerChoice',
                title: 'Selected',
                sortField: 'answerChoice',
                width: '30%',
            }, 
            ];
        },
   },
   methods: {
       onCancel() {
           this.$emit('cancel');
       },
   },
   watch: {
       data: {
           handler() {
               this.s_data = this.data;
           },
           immediate: true,
       },
   },
}
</script>